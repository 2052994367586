import { Brand } from "effect"

export type Route = Brand.Branded<string, "Route">
export const Route = Brand.nominal<Route>()

export const dashboard = Route("/")

export const chipsCheckDuplicates = Route("/chips/check-duplicates")

export const chipsIngestV1 = Route("/chips/ingest-v1")

export const chipsIngestV2 = Route("/chips/ingest-v2")

export const chipsMigrate = Route("/chips/migrate")

export const chipsGroups = Route("/chip-groups")

export const chipsGroupsNew = Route("/chip-groups/new")

export const chipGroupDetail = (id: string) => Route(`/chip-groups/${id}`)

export const login = Route("/login")

export const logout = Route("/logout")

export const postcardsSetVideo = Route("/postcards/set-video")

export const solanaOrders = Route("/solana/orders")

export const solanaOrderDetail = (id: string) => Route(`/solana/orders/${id}`)

export const tagGroupsExplode = Route("/tag-groups/explode")
